import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { usePeticion } from "../herramientas";

export default function Index() {
  const [estado, setEstado] = useState("formulario");
  const [imagen, setImagen] = useState("Sin imagen");
  const peticion = usePeticion();
  const nombreOperacion = "Agregar producto nuevo";
  const navigate = useNavigate();

  function handleSubmit(event) {
    setEstado("cargando");
    event.preventDefault();

    let formData = new FormData(event.currentTarget);

    peticion(
      "producto_agregar/agregar/",
      {
        method: "POST",
        body: formData,
      },
      nombreOperacion,
      "El producto no ha podido ser agregado satisfactoriamente",
      {
        titulo: nombreOperacion,
        mensaje: "Producto agregado satisfactoriamente",
        botones: [
          {
            nombre: "Agregar otro producto nuevo",
            color: "success",
            direccion: "/producto_agregar",
          },
        ],
      }
    );
  }

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado == "formulario") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button variant="contained" component="label">
                Cargar imagen
                <input hidden accept="image/*" name="imagen" type="file" onChange={(e) => setImagen(e.target.files[0].name)} />
              </Button>
              <Typography sx={{ mx: 2 }} variant="body">
                {imagen}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Detalles</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField fullWidth label="Codigo" defaultValue="" required name="codigo" />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField fullWidth label="Nombre" defaultValue="" name="nombre" />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField fullWidth label="Cantidad" defaultValue="0" name="cantidad" type="number" inputProps={{ min: 0, step: "1" }} />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField fullWidth label="Precio de costo" defaultValue="0" name="precio_costo" type="number" inputProps={{ min: 0, step: "0.01" }} />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField fullWidth label="Precio de venta" defaultValue="0" name="precio_venta" type="number" inputProps={{ min: 0, step: "0.01" }} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Descripción" multiline defaultValue="" name="descripcion" rows={5} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" mb={2} spacing={2}>
                <Button variant="contained" type="submit" color="success">
                  Agregar producto
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
