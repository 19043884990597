import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [usuario, setUsuario] = useState({})
  const [contrasena, setContrasena] = useState("")
  const [contrasenaRepetida, setContrasenaRepetida] = useState("")

  const peticion = usePeticion();

  const nombreOperacion = "Cambiar contraseña";

  const navigate = useNavigate();

  useEffect(()=>{
    peticion("cuenta_configuracion/datos/", {}, nombreOperacion, "No han podido ser cargados los datos de la cuenta", (json)=>{
      setUsuario(json.usuario)
      setEstado("cargado")
    })
  }, []) 

  function handleSubmit(event) {
    if (contrasena != contrasenaRepetida) {
      alert("Las contraseñas no coinciden.")
    } else {
      setEstado("cargando");
      event.preventDefault();
      let formData = new FormData(event.currentTarget);
      peticion(
        "cuenta_configuracion/cambiar_contrasena/",
        {
          method: "POST",
          body: formData,
        },
        nombreOperacion,
        "No se ha podido cambiar la contraseña de la cuenta",
        {
          mensaje: "Contraseña actualizada satisfactoriamente",
          botones: [{
            nombre: "Configuración de cuenta",
            color: "success",
            direccion: "/cuenta_configuracion",
          }]
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Contraseña nueva" type="password" required value={contrasena} onChange={(e)=>{setContrasena(e.target.value)}} name="contrasena" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Repita contraseña nueva" type="password" required value={contrasenaRepetida} onChange={(e)=>{setContrasenaRepetida(e.target.value)}} name="contrasena_repetida" />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" mb={2} spacing={2}>
                <Button variant="contained" type="button" onClick={()=>navigate("../")} color="secondary">
                  Cancelar
                </Button>
                <Button variant="contained" type="submit" color="success">
                  Actualizar contraseña
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
