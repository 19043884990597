import React from 'react';
import ReactDOM from 'react-dom/client';

import { Exito, Error, NoAutorizado } from './confirmaciones';

import App from './App';

import Inicio from './inicio/index'

import IniciarSesion from './iniciar_sesion/index'
import ClienteAgregar from './cliente_agregar/index'
import ClienteModificar from './cliente_modificar/index'
  import ClienteModificarFormulario from './cliente_modificar/formulario'
import ClienteConsultar from './cliente_consultar/index'
  import ClienteConsultarDetalles from './cliente_consultar/detalles'

import ProductoAgregar from './producto_agregar/index'
import ProductoModificar from './producto_modificar/index'
  import ProductoModificarFormulario from './producto_modificar/formulario'
import ProductoConsultar from './producto_consultar/index'
  import ProductoConsultarDetalles from './producto_consultar/detalles'

import VentaAgregar from './venta_agregar/index'
import VentaModificar from './venta_modificar/index'
  import VentaModificarFormulario from './venta_modificar/formulario'
import VentaConsultar from './venta_consultar/index'
  import VentaConsultarDetalles from './venta_consultar/detalles'
import VentasDeclararEntregas from './ventas_declarar_entregas/index'

import EstadisciasVendedores from './estadisticas_vendedores/index'

import CuentaConfiguracion from './cuenta_configuracion/index'
  import CuentaConfiguracionCambiarNombre from './cuenta_configuracion/cambiar_nombre'
  import CuentaConfiguracionCambiarUsuario from './cuenta_configuracion/cambiar_usuario'
  import CuentaConfiguracionCambiarContrasena from './cuenta_configuracion/cambiar_contrasena'

import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

function Proteger(props) {

  if (sessionStorage.getItem("token") == null) {
    return <Navigate to="/iniciar_sesion"></Navigate>
  } else {
    return <App {...props} />
  }
}

const router = createBrowserRouter([
  {
    path: "iniciar_sesion",
    element: <IniciarSesion />
  },
  {
    path: "/",
    element: <Proteger />,
    children: [
      {path: "exito", element: <Exito/>},
      {path: "error", element: <Error/>},
      {path: "no_autorizado", element: <NoAutorizado/>},

      {path: "", element: <Inicio />},

      {path: "cliente_agregar", element: <ClienteAgregar />},
      {path: "cliente_modificar", element: <Outlet />, children: [
        {path: "", element: <ClienteModificar />},
        {path: "formulario/:id_cliente", element: <ClienteModificarFormulario />}
      ]},
      {path: "cliente_consultar", element: <Outlet />, children: [
        {path: "", element: <ClienteConsultar />},
        {path: "detalles/:id_cliente", element: <ClienteConsultarDetalles />}
      ]},

      {path: "producto_agregar", element: <ProductoAgregar />},
      {path: "producto_modificar", element: <Outlet />, children: [
        {path: "", element: <ProductoModificar />},
        {path: "formulario/:id_producto", element: <ProductoModificarFormulario />}
      ]},
      {path: "producto_consultar", element: <Outlet />, children: [
        {path: "", element: <ProductoConsultar />},
        {path: "detalles/:id_producto", element: <ProductoConsultarDetalles />}
      ]},

      {path: "venta_agregar", element: <VentaAgregar />},
      {path: "venta_modificar", element: <Outlet />, children: [
        {path: "", element: <VentaModificar />},
        {path: "formulario/:id_venta", element: <VentaModificarFormulario />}
      ]},
      {path: "venta_consultar", element: <Outlet />, children: [
        {path: "", element: <VentaConsultar />},
        {path: "detalles/:id_venta", element: <VentaConsultarDetalles />}
      ]},
      {path: "ventas_declarar_entregas", element: <Outlet />, children: [
        {path: "", element: <VentasDeclararEntregas />}
      ]},

      {path: "estadisticas_vendedores", element: <EstadisciasVendedores />},

      {path: "cuenta_configuracion", element: <Outlet />, children: [
        {path: "", element: <CuentaConfiguracion />},
        {path: "cambiar_nombre", element: <CuentaConfiguracionCambiarNombre />},
        {path: "cambiar_usuario", element: <CuentaConfiguracionCambiarUsuario />},
        {path: "cambiar_contrasena", element: <CuentaConfiguracionCambiarContrasena />},
      ]},

    ]
  }
])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
