import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Unstable_Grid2";

import { usePeticion } from "../herramientas";
import { Card, CardContent } from "@mui/material";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [usuario, setUsuario] = useState({})
  const peticion = usePeticion();
  const nombreOperacion = "Configuración de cuenta";
  const navigate = useNavigate();

  useEffect(()=>{
    peticion("cuenta_configuracion/datos/", {}, nombreOperacion, "No han podido ser cargados los datos de la cuenta", (json)=>{
      setUsuario(json.usuario)
      setEstado("cargado")
    })
  }, []) 

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5">Cambiar nombre</Typography>
                <Typography>
                  Cambiar el nombre propio de la persona que usa la cuenta. <br />
                  Actualmente es: <b>{usuario.nombre}</b>.
                </Typography>
                <Button sx={{mt:2}} variant="contained" onClick={()=>navigate("cambiar_nombre")}>Cambiar</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5">Cambiar usuario</Typography>
            <Typography>
              Cambiar el nombre de usuario con el que se accede a la cuenta.
              <br />
              Actualmente es: <b>{usuario.usuario}</b>.
            </Typography>
            <Button sx={{mt:2}} variant="contained" onClick={()=>navigate("cambiar_usuario")}>Cambiar</Button>
          </CardContent>
        </Card>

          </Grid>
          <Grid xs={12} md={6}>
        <Card>
          <CardContent>
          <Typography variant="h5">Cambiar contraseña</Typography>
            <Typography>Cambiar la contraseña con la que se accede a la cuenta.</Typography>
            <Button sx={{mt:2}} variant="contained" onClick={()=>navigate("cambiar_contrasena")}>Cambiar</Button>
          </CardContent>
        </Card>

          </Grid>
        </Grid>
      </>
    );
  }
}
