import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { API_URL } from "./conexion";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";

import Icon from "@mui/material/Icon";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { usePeticion, OperacionesContext } from "./herramientas";

// const drawerWidth = 240;
const drawerWidth = 360;

function App(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const peticion = usePeticion();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();
  const [operaciones, setOperaciones] = useState([]);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
    },
  });

  function handleCerrarSesion(e) {
    e.preventDefault();
    fetch(API_URL + "cerrar_sesion/", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      }),
    });
    sessionStorage.removeItem("token");
    navigate("/iniciar_sesion");
  }

  useEffect(function () {
    peticion("operaciones_usuario/", {}, "Inicio", "No han podido ser cargadas las operaciones disponibles para este usuario", (json)=>{
      
      let operacionesasd = [];
      for (let i = 0; i < json.operaciones.length; i++) {
        if (i != 0) {
          if (json.operaciones[i]["modulo"] != json.operaciones[i - 1]["modulo"]) {
            operacionesasd.push(<Divider key={i} />);
          }
        }
        operacionesasd.push(
          <ListItem key={json.operaciones[i].id_operacion} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/" + json.operaciones[i].id_operacion);
              }}
            >
              <ListItemIcon>
                <ListItemIcon>
                  <Icon>{json.operaciones[i].icono}</Icon>
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText primary={json.operaciones[i].nombre} />
            </ListItemButton>
          </ListItem>
        );
      }
      setOperaciones(operacionesasd);
    })
  }, []);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List sx={{ p: 0 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <Icon>home</Icon>
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItemButton>
        </ListItem>
        <Divider />
        {operaciones}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={handleCerrarSesion}>
            <ListItemIcon>
              <Icon>logout</Icon>
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <OperacionesContext.Provider value={operaciones}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box sx={{ display: "flex", bgcolor: "background.default", color: "text.primary", height: "100%" }}>
          <AppBar
            position="fixed"
            sx={{
              width: { lg: `calc(100% - ${drawerWidth}px)` },
              ml: { lg: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { lg: "none" } }}>
                <Icon>menu</Icon>
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                BM Importaciones
              </Typography>
            </Toolbar>
          </AppBar>
          <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <SwipeableDrawer onClick={handleDrawerToggle}
              container={container}
              variant="temporary"
              open={mobileOpen}
              onOpen={handleDrawerToggle}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", lg: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
            >
              {drawer}
            </SwipeableDrawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", lg: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar />
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
      </OperacionesContext.Provider>
    </>
  );
}

export default App;
