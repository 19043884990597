import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import { useNavigate, useLocation } from "react-router-dom";

export function Exito(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {state.titulo}
      </Typography>
      <Alert severity="success" sx={{ mb: 2 }}>
        <AlertTitle>{state.mensaje}</AlertTitle>
      </Alert>
      {state.botones.map((boton, index) => (
        <Button key={index} onClick={() => navigate(boton.direccion)} variant="contained" color={boton.color}>
          {boton.nombre}
        </Button>
      ))}
    </>
  );
}

export function Error(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {state.titulo}
      </Typography>
      <Alert severity="error" sx={{ mb: 2 }}>
        <AlertTitle>{state.mensaje}</AlertTitle>
        Toma una captura de pantalla y envíasela a Pablo.
        <br />
        <br />
        {state.error}
      </Alert>
      <Button target="_blank" href="https://wa.me/51998884006" variant="contained" color="error" startIcon={<Icon>question_answer</Icon>}>
        WhatsApp con Pablo
      </Button>
    </>
  );
}

export function NoAutorizado(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {state.titulo}
      </Typography>
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Usted no está autorizado para realizar esta operación</AlertTitle>
      </Alert>
      <Button onClick={() => navigate("/")} variant="contained" color="warning">
        Ir a Inicio
      </Button>
    </>
  );
}