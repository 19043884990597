import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function Index() {
  const [estado, setEstado] = useState("formulario");
  const peticion = usePeticion();

  const nombreOperacion = "Agregar cliente nuevo";

  const navigate = useNavigate();

  function handleSubmit(event) {
    setEstado("cargando");
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    peticion(
      "cliente_agregar/agregar/",
      {
        method: "POST",
        body: formData,
      },
      nombreOperacion,
      "El cliente no ha podido ser agregado satisfactoriamente",
      {
        mensaje: "Cliente agregado satisfactoriamente",
        botones: [{
          nombre: "Agregar otro cliente nuevo",
          color: "success",
          direccion: "/cliente_agregar",
        }]
      }
    );
  }

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado == "formulario") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="Nombre" defaultValue="" required name="nombre" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="DNI" defaultValue="" name="dni" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Teléfono" defaultValue="" name="telefono" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Ciudad" defaultValue="" name="ciudad" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Agencia" defaultValue="" name="agencia" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Destino" multiline defaultValue="" name="destino" rows={3} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Observaciones" multiline defaultValue="" name="observaciones" rows={5} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" mb={2} spacing={2}>
                <Button variant="contained" type="submit" color="success">
                  Agregar cliente
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
