import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Chart, BarSeries, Title, ArgumentAxis, ValueAxis } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";

import { usePeticion } from "../herramientas";
import { Card, CardContent } from "@mui/material";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [usuario, setUsuario] = useState({});
  const [cantidades, setCantidades] = useState([]);
  const [productos, setProductos] = useState([]);
  const [cantidadHoy, setCantidadHoy] = useState(0)
  const peticion = usePeticion();
  const nombreOperacion = "Estadísticas ventas";
  const navigate = useNavigate();

  useEffect(() => {
    peticion("estadisticas_vendedores/estadisticas/", {}, nombreOperacion, "La data no ha podido ser cargada", (json) => {
      setCantidades(json.cantidades);
      setUsuario(json.usuario);
      setProductos(json.ranking_productos);
      setCantidadHoy(json.cantidad_hoy);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <Paper>
              <Card>
                <CardContent sx={{ mb: 0 }}>
                  <Typography color="text.secondary" gutterBottom>
                    {"Cantidad de unidades vendidas hoy " + usuario.nombre }
                  </Typography>
                  <Typography variant="h1">{cantidadHoy}</Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <Paper>
              <Card>
                <CardContent sx={{ mb: 0 }}>
                  <Typography color="text.secondary" gutterBottom>
                    {"Procentaje de meta diaria " + usuario.nombre }
                  </Typography>
                  <Typography variant="h1">{`${(cantidadHoy/200)*100}%`}</Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <Paper>
              <Chart data={cantidades.reverse()}>
                <ArgumentAxis />
                <ValueAxis max={7} />

                <BarSeries valueField="cantidad" argumentField="fecha_creacion" />
                <Title text={"Unidades vendidas " + usuario.nombre} />
                {/* <Animation /> */}
              </Chart>
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      {"Ranking más vendidos 7 últimos días "+ usuario.nombre}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" component="th">Cantidad</TableCell>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Producto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productos.map((row) => (
                    <TableRow key={row.codigo}>
                      <TableCell align="left">{row.cantidad}</TableCell>
                      <TableCell align="left">{row.codigo}</TableCell>
                      <TableCell align="left">{row.nombre}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
