import logotipo from "./logotipo.jpg";
// import estilos from '!raw-loader!../acceso/signin.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePeticion } from "../herramientas";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Alert from "@mui/material/Alert";

export default function IniciarSesion(props) {
  const [input, setInput] = useState({ usuario: "", contrasena: "" });
  const [alerta, setAlerta] = useState(false);
  const [estado, setEstado] = useState("formulario");
  const peticion = usePeticion();

  const navigate = useNavigate();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    sessionStorage.removeItem("token");
  }, []);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setInput({
      ...input,
      [name]: value,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");

    let formData = new FormData(event.currentTarget);

    peticion(
      "iniciar_sesion/",
      {
        method: "POST",
        body: formData,
      },
      "Iniciar sesión",
      "No ha podido realizarse la autenticación de usuario",
      (json) => {
        sessionStorage.removeItem("token");
        if (json.respuesta === "sesion iniciada") {
          sessionStorage.setItem("token", json.token);
          navigate("/");
        } else {
          setAlerta(true);
          setEstado("formulario");
        }
      }
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs" sx={{ margin: "auto", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logotipo} width="200"></img>
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            Sistema BM Importaciones
          </Typography>
          {alerta ? (
            <Alert sx={{ mt: 2, width: "100%" }} severity="error">
              Usuario o contraseña incorrecta.
            </Alert>
          ) : null}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="usuario" label="Usuario" name="usuario" value={input.usuario} onChange={handleInputChange} />
            <TextField
              margin="normal"
              required
              fullWidth
              name="contrasena"
              label="Contraseña"
              type="password"
              id="contrasena"
              value={input.contrasena}
              onChange={handleInputChange}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button disabled={estado == "cargando" ? true : false} type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
              {estado == "cargando" ? "Cargando..." : "Iniciar sesión"}
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1, mb: 2 }}>
            {"Copyright © "}
            <Link color="inherit" target="_blank" href="https://www.facebook.com/BM-Importaciones-100493055901984">
              BM Importaciones
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
