import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePeticion } from "../herramientas";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Icon from "@mui/material/Icon";

console.clear();

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const nombreOperacion = "Modificar producto";
  const [productos, setProductos] = useState([]);
  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(function () {
    peticion("producto_modificar/lista/", {}, nombreOperacion, "No ha podido ser cargada la lista de productos", (json)=>{
      setProductos(json.productos);
        setEstado("cargado");
    })
  }, []);

  const columnas = [
    {
      field: "id_producto",
      headerName: "Consultar",
      renderCell: (params) => (
        <Button
          onClick={() => navigate("formulario/" + params.value)}
          variant="contained"
        >
          <Icon>edit</Icon>
        </Button>
      ),
    },
    { field: "codigo", headerName: "Código" },
    { field: "nombre", headerName: "Producto", minWidth: 200, flex: 2 },
    { field: "cantidad", headerName: "Cantidad" },
    {
      field: "precio_venta",
      headerName: "Precio",
      renderCell: (params) => "S/ " + params.value,
    },
  ];

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado === "cargado") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Box sx={{height: '80%', mx: {xs: -3, lg: 0}}}>
        <DataGrid
          rows={productos}
          getRowId={(row) => row.id_producto}
          columns={columnas}
        />
        </Box>
      </>
    );
  }
}
