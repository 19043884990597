import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePeticion } from "../herramientas";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [detalles, setDetalles] = useState({});
  const [error, setError] = useState("");
  const params = useParams();
  const peticion = usePeticion();

  const nombreOperacion = "Modificar nuevo";

  const navigate = useNavigate();

  useEffect(function () {
    peticion("cliente_modificar/detalles/" + params.id_cliente, {}, nombreOperacion, "No se han podido cargar los datos del cliente", (json) => {
      setDetalles(json.detalles);
      setEstado("formulario");
    });
  }, []);

  function handleSubmit(event) {
    setEstado("cargando");
    event.preventDefault();

    let formData = new FormData(event.currentTarget);

    peticion(
      "cliente_modificar/",
      {
        method: "PUT",
        body: formData,
      },
      nombreOperacion,
      "No ha sido modificado el cliente satisfatoriamente",
      {
        mensaje: "Cliente modificado satisfactoriamente",
        botones: [
          {
            nombre: "Modificar otro cliente",
            color: "success",
            direccion: "/cliente_modificar"
          },
        ],
      }
    );
  }

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado == "formulario") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="id_cliente" value={detalles.id_cliente} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="Nombre" defaultValue={detalles.nombre} required name="nombre" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="DNI" defaultValue={detalles.dni} name="dni" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Teléfono" defaultValue={detalles.telefono} name="telefono" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Ciudad" defaultValue={detalles.ciudad} name="ciudad" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Agencia" defaultValue={detalles.agencia} name="agencia" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Destino" multiline defaultValue={detalles.destino} name="destino" rows={3} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Observaciones" multiline defaultValue={detalles.observaciones} name="observaciones" rows={5} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" mb={2} spacing={2}>
                <Button variant="contained" type="submit" color="warning">
                  Modificar cliente
                </Button>
                <Button variant="contained" color="error" onClick={() => navigate(-1)}>
                  Cancelar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
