import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePeticion } from "../herramientas";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Icon from "@mui/material/Icon";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [clientes, setClientes] = useState([]);
  const navigate = useNavigate();
  const nombreOperacion = "Modificar cliente";
  const peticion = usePeticion();

  useEffect(function () {
    peticion("cliente_modificar/lista/", {}, nombreOperacion, "No han podido ser cargados los datos del cliente", (json)=>{
      setClientes(json.clientes);
      setEstado("cargado");
    })
  }, []);

  const columnas = [
    {
      field: "id_cliente",
      headerName: "Modificar",
      renderCell: (params) => (
        <Button
          onClick={() => navigate("formulario/" + params.value)}
          variant="contained"
        >
          <Icon>edit</Icon>
        </Button>
      ),
    },
    { field: "nombre", headerName: "Cliente", minWidth: 200, flex: 2 },
    { field: "dni", headerName: "DNI", minWidth: 100, flex: 1 },
    { field: "telefono", headerName: "Teléfono", minWidth: 100, flex: 1 },
  ];

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado === "cargado") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Box sx={{height: '80%', mx: {xs: -3, lg: 0}}}>
          <DataGrid
            rows={clientes}
            getRowId={(row) => row.id_cliente}
            columns={columnas}
          />
        </Box>
      </>
    );
  }
}
