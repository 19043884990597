import { useNavigate } from "react-router-dom";
import { API_URL } from "./conexion"
import { createContext } from "react";

export const OperacionesContext = createContext([]);

export function useFecha() {
  return (strFecha) => {
    let fecha = new Date(strFecha);
    return [fecha.getUTCDate(), "/", fecha.getUTCMonth()+1, "/", fecha.getUTCFullYear()];
  };
}

export function usePeticion() {
  const navigate = useNavigate();
  return function(ruta, opciones = {}, nombreOperacion, mensajeError, exito) {
    fetch(API_URL + ruta, {
      headers: new Headers({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      ...opciones,
    })
      .then(function (respuesta) {
        return respuesta.json();
      })
      .then(function (json) {
        if (json.estado === "exito") {
          if (typeof(exito) === 'function') {
            exito(json);
          } else {
            navigate("/exito", {
              state: {titulo: nombreOperacion, ...exito}
            });
          }
        } else if (json.estado === "error") {
          navigate("/error", {
            state: {
              titulo: nombreOperacion,
              mensaje: mensajeError,
              error: json.error,
            },
          });
        } else if (json.estado === "no autorizado") {
          navigate("/no_autorizado", { state: { titulo: nombreOperacion } });
        }
      })
      .catch((e)=>{
        navigate("/error", { state: { titulo: nombreOperacion, mensaje: mensajeError, error: e.message } });
      })
  };
}