import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePeticion } from "../herramientas";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DataGrid } from "@mui/x-data-grid";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [requerimientos, setRequerimientos] = useState({});
  const [errorCantidad, setErrorCantidad] = useState(false);
  const [cantidadDisponible, setCantidadDisponible] = useState("");

  const [idCliente, setIdCliente] = useState("");
  const [idProducto, setIdProducto] = useState("");
  const [precioVenta, setPrecioVenta] = useState(0.0);
  const [importe, setImporte] = useState(0.0);
  const [cantidad, setCantidad] = useState();
  const [abono, setAbono] = useState(0.00);
  const [estadoVenta, setEstadoVenta] = useState()
  const [observaciones, setObservaciones] = useState("")
  const [idVendedor, setIdVendedor] = useState(0)

  const [botonCargando, setBotonCargando] = useState(false);
  const [abrirDialogoClientes, setAbrirDialogoClientes] = useState(false);
  const [abrirDialogoProductos, setAbrirDialogoProductos] = useState(false);

  const [clientes, setClientes] = useState([])
  const [clientesFiltrados, setClientesFiltrados] = useState([])
  const [productos, setProductos] = useState([])
  const [productosFiltrados, setProductosFiltrados] = useState([])

  const [busquedaCliente, setBusquedaCliente] = useState("")
  const [busquedaProducto, setBusquedaProducto] = useState("")

  const nombreOperacion = "Modificar venta";

  const params = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("venta_modificar/requerimientos/", {}, nombreOperacion, "No se pueden cargar los clientes y productos", (json) => {
      setRequerimientos({
        usuarios: json.usuarios,
        id_usuario: json.id_usuario
      });
      setClientes(json.clientes)
      setClientesFiltrados(json.clientes)
      setProductos(json.productos)
      setProductosFiltrados(json.productos)

      peticion("venta_modificar/detalles/"+ params.id_venta, {}, nombreOperacion, "", (json) => {
        setIdProducto(json.detalles.id_producto)
        setIdCliente(json.detalles.id_cliente)
        setCantidad(json.detalles.cantidad)
        setAbono(json.detalles.abono)
        setEstadoVenta(json.detalles.estado)
        setIdVendedor(json.detalles.id_vendedor)

        setEstado("formulario");
      });
    });
  }, []);

  function handleSubmit(event) {
    setBotonCargando(true);
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    peticion(
      "venta_modificar/calcular_importe/",{ method: "POST", body: formData },
      nombreOperacion,
      "No ha podido ser comprobada la cantidad restante del producto seleccionado",
      (json) => {
        setBotonCargando(false);
        if (json.cantidad < formData.get("cantidad")) {
          alert("No hay suficiente cantidad de inventario de este producto.\nHay " + json.cantidad + " unidades.");
        } else {
          setEstado("cargando");
          peticion("venta_modificar/modificar/", { method: "PUT", body: formData }, nombreOperacion, "La venta no ha podido ser modificada satisfactoriamente", {
            mensaje: "Venta modificada satisfactoriamente",
            botones: [
              {
                nombre: "Modificar otra venta",
                color: "success",
                direccion: "/venta_modificar",
              },
            ],
          });
        }
      }
    );
  }

  useEffect(function () {
    if (cantidad != "" && idProducto != "") {
      var formData = new FormData();
      formData.append("cantidad", cantidad);
      formData.append("id_producto", idProducto);
      formData.append("id_venta", params.id_venta);
      peticion(
        "venta_modificar/calcular_importe/",
        { method: "POST", body: formData },
        nombreOperacion,
        "No ha podido ser calculado el importe para la cantidad y el producto seleccionado",
        (json) => {
          setPrecioVenta(json.detalles.precio_venta);
          setImporte(json.detalles.importe);
          setCantidadDisponible(json.detalles.cantidad);
          if (cantidad > json.detalles.cantidad) {
            setErrorCantidad(true);
          } else {
            setErrorCantidad(false);
          }
        }
      );
    }
  },[cantidad, idProducto]);

  function handleChangeCantidad(event) {
    setCantidad(event.target.value);
  }

  function handleChangeIdCliente(event) {
    setIdCliente(event.target.value);
  }
  function handleChangeIdProducto(event) {
    setIdProducto(event.target.value);
  }

  function handleAbrirDialogoClientes() {
    setAbrirDialogoClientes(true);
  }
  function handleCerrarDialogoClientes() {
    setAbrirDialogoClientes(false);
  }
  function handleAbrirDialogoProductos() {
    setAbrirDialogoProductos(true);
  }
  function handleCerrarDialogoProductos() {
    setAbrirDialogoProductos(false);
  }

  function handleChangeBusquedaCliente(event) {
    setBusquedaCliente(event.target.value)
  }

  function handleChangeBusquedaProducto(event) {
    setBusquedaProducto(event.target.value)
  }

  useEffect(function () {
    let asd = clientes.filter(fila => {
      if (fila.nombre.toUpperCase().includes(busquedaCliente.toUpperCase()) || fila.dni.toUpperCase().includes(busquedaCliente.toUpperCase()) || fila.telefono.toUpperCase().includes(busquedaCliente.toUpperCase())) {
        return true
      }
      return false
    })
    setClientesFiltrados(asd)
  }, [busquedaCliente])

  useEffect(function () {
    let asd = productos.filter(fila => {
      if (fila.codigo.toUpperCase().includes(busquedaProducto.toUpperCase()) || fila.nombre.toUpperCase().includes(busquedaProducto.toUpperCase())) {
        return true
      }
      return false
    })
    setProductosFiltrados(asd)
  }, [busquedaProducto])

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado == "formulario") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="id_venta" value={params.id_venta} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                select
                label="Cliente"
                value={idCliente}
                name="id_cliente"
                onChange={handleChangeIdCliente}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleAbrirDialogoClientes}>
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>Seleccionar...</em>
                </MenuItem>
                {clientes.map((cliente) => (
                  <MenuItem key={cliente.id_cliente} value={cliente.id_cliente}>
                    {cliente.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                select
                label="Producto"
                value={idProducto}
                name="id_producto"
                onChange={handleChangeIdProducto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleAbrirDialogoProductos}>
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="">
                  <em>Seleccionar...</em>
                </MenuItem>
                {productos.map((producto) => (
                  <MenuItem key={producto.id_producto} value={producto.id_producto}>
                    {producto.codigo + " " + producto.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField required fullWidth select label="Vendedor" name="id_vendedor" defaultValue={idVendedor}>
                {requerimientos.usuarios.map((usuario) => (
                  <MenuItem key={usuario.id_usuario} value={usuario.id_usuario}>
                    {usuario.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                required
                fullWidth
                label="Cantidad"
                onChange={handleChangeCantidad}
                defaultValue={cantidad}
                name="cantidad"
                type="number"
                inputProps={{ min: 1, step: "1" }}
                error={errorCantidad == true ? true : false}
                helperText={"Unidades disponibles: " + cantidadDisponible}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField disabled fullWidth value={precioVenta} label="Precio" />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField disabled fullWidth value={importe} label="Importe" />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField required fullWidth label="Abono" defaultValue={abono} name="abono" type="number" inputProps={{ min: 0, step: "0.01" }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth select label="Estado" name="estado" defaultValue={estadoVenta}>
                <MenuItem value={"Debe"}>Debe</MenuItem>
                <MenuItem value={"Pagado"}>Pagado</MenuItem>
                <MenuItem value={"Entregado"}>Entregado</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Observaciones" multiline defaultValue={observaciones} name="observaciones" rows={5} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" mb={2} spacing={2}>
                <Button variant="contained" type="submit" color="warning" disabled={errorCantidad}>
                  {botonCargando ? "Cargando..." : "Modificar venta"}
                </Button>
                <Button variant="contained" color="error" onClick={() => navigate(-1)}>
                  Cancelar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
        <Dialog fullScreen open={abrirDialogoClientes}>
          <DialogTitle>Seleccionar cliente</DialogTitle>
          <DialogContent>
            <Stack spacing={2} sx={{ height: "100%" }}>
                <TextField fullWidth label="Nombre, DNI o teléfono" onChange={handleChangeBusquedaCliente} variant="outlined" />
                  <DataGrid
                    scroll="paper"
                    getRowId={(row) => row.id_cliente}
                    rows={clientesFiltrados}
                    columns={[
                      {
                        field: "id_cliente",
                        headerName: "Seleccionar",
                        renderCell: (params) => (
                          <Button
                            onClick={() => {
                              setIdCliente(params.value);
                              handleCerrarDialogoClientes();
                            }}
                            variant="contained"
                          >
                            <Icon>done</Icon>
                          </Button>
                        ),
                      },
                      { field: "nombre", headerName: "Nombre", flex: 2 },
                      { field: "telefono", headerName: "Teléfono", flex: 1 },
                      { field: "dni", headerName: "DNI", flex: 1 },
                    ]}
                  />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCerrarDialogoClientes}>Cerrar</Button>
          </DialogActions>
        </Dialog>
        <Dialog fullScreen open={abrirDialogoProductos}>
          <DialogTitle>Seleccionar producto</DialogTitle>
          <DialogContent>
          <Stack spacing={2} sx={{ height: "100%" }}>
          <TextField fullWidth label="Nombre o código" onChange={handleChangeBusquedaProducto} variant="outlined" />
              <DataGrid
                scroll="paper"
                getRowId={(row) => row.id_producto}
                rows={productosFiltrados}
                columns={[
                  {
                    field: "id_producto",
                    headerName: "Seleccionar",
                    renderCell: (params) => (
                      <Button
                        onClick={() => {
                          setIdProducto(params.value);
                          handleCerrarDialogoProductos();
                        }}
                        variant="contained"
                      >
                        <Icon>done</Icon>
                      </Button>
                    ),
                  },
                  { field: "codigo", headerName: "Código", flex: 1 },
                  { field: "nombre", headerName: "Nombre", flex: 2 },
                  { field: "precio_venta", headerName: "Precio", flex: 1 },
                  { field: "cantidad", headerName: "Cantidad", flex: 1 },
                ]}
              />
              </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCerrarDialogoProductos}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
