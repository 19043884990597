import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../conexion";
import { useFecha } from "../herramientas";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { usePeticion } from "../herramientas";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [detalles, setDetalles] = useState({});
  const nombreOperacion = "Consultar producto";
  const peticion = usePeticion();

  const params = useParams();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("producto_consultar/detalles/" + params.id_producto, {}, nombreOperacion, "No han podido ser cargados los detalles del producto", (json) => {
      setDetalles(json.detalles);
      setEstado("cargado");
    });
  }, []);

  if (estado == "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                alt="Este producto no tiene imagen"
                image={API_URL + "static/img/productos/" + params.id_producto + "/principal.jpeg"}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Código
                    </TableCell>
                    <TableCell align="right">{detalles.codigo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Nombre
                    </TableCell>
                    <TableCell align="right">{detalles.nombre}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Cantidad
                    </TableCell>
                    <TableCell align="right">{detalles.cantidad}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Precio de venta
                    </TableCell>
                    <TableCell align="right">S/ {detalles.precio_venta}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box sx={{ pb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Descripción
          </Typography>
          <Typography variant="p" gutterBottom>
            {detalles.descripcion}
          </Typography>
        </Box>
      </>
    );
  }
}
