import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFecha, usePeticion } from "../herramientas";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Icon from "@mui/material/Icon";
import { Stack } from "@mui/system";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

console.clear();

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const nombreOperacion = "Declarar entregas";
  const [ventas, setVentas] = useState([]);
  const [ventasFiltradas, setVentasFiltradas] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [filtroEstado, setFiltroEstado] = useState("Todos");

  const afecha = useFecha();
  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(function () {
    peticion("ventas_declarar_entregas/lista/", {}, nombreOperacion, "No ha podido cargarse la lista de ventas", (json) => {
      setVentas(json.ventas);
      setVentasFiltradas(json.ventas);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }

  function handleChangeFiltroEstado(event) {
    setFiltroEstado(event.target.value);
  }

  function filtrar() {
    let asd = ventas.filter((fila) => {
      if (fila.nombre_cliente.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda)) {
        if (fila.estado == filtroEstado || filtroEstado == "Todos") {
          return true;
        }
      }
      return false;
    });
    setVentasFiltradas(asd);
  }

  useEffect(
    filtrar, [busqueda, filtroEstado]
  );

  function declararEntrega(id_venta) {
    peticion("ventas_declarar_entregas/declarar_entrega/" + id_venta, {}, nombreOperacion, "No ha podido actualizarse el estado de la venta.", (json) => {
      let asd = ventas.map((fila)=>{
        if (fila.id_venta == json.venta.id_venta) {
          fila.estado = json.venta.estado
        }
        return fila
      })
      setVentas(asd)
    })
  }

  useEffect(()=>{
    filtrar();
  }, [ventas])

  const columnas = [
    {
      field: "id_venta",
      headerName: "Entregar",
      renderCell: (params) => (
        <Button onClick={() => declararEntrega(params.value)} variant="contained" disabled={params.row["estado"] == "Entregado"}  color="success">
          <Icon>outbox</Icon>
        </Button>
      ),
    },
    { field: "fecha_creacion", headerName: "Fecha", minWidth: 100, flex: 1, renderCell: (params) => afecha(params.value) },
    { field: "nombre_cliente", headerName: "Cliente", minWidth: 200, flex: 2 },
    { field: "dni", headerName: "DNI", minWidth: 100, flex: 1 },
    { field: "codigo_producto", headerName: "Código" },
    { field: "nombre_producto", headerName: "Producto", minWidth: 200},
    {
      field: "estado",
      headerName: "Estado",
      renderCell: (params) => (
        <Box sx={{ color: params.value == "Debe" ? "error.main" : params.value == "Pagado" ? "primary.main" : "success.main" }}>{params.value}</Box>
      ),
    },
    { field: "cantidad", headerName: "Cantidad" },
    { field: "importe", headerName: "Importe", description: "Cantidad x Precio" },
    { field: "abono", headerName: "Abono" },
    { field: "nombre_vendedor", headerName: "Vendedor", minWidth: 150, flex: 1.5 },
  ];

  if (estado === "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (estado === "cargado") {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={8}>
            <TextField fullWidth value={busqueda} label="Nombre o DNI" onChange={handleChangeBusqueda} variant="outlined" />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select value={filtroEstado} label="Estado" onChange={handleChangeFiltroEstado}>
                <MenuItem value="Todos">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value={"Pagado"}>Pagado</MenuItem>
                <MenuItem value={"Entregado"}>Entregado</MenuItem>
                <MenuItem value={"Debe"}>Debe</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ height: "80%", mx: { xs: -3, lg: 0 } }}>
          <DataGrid rows={ventasFiltradas} getRowId={(row) => row.id_venta} columns={columnas} />
        </Box>
      </>
    );
  }
}
