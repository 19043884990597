import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import { useEffect, useState, useContext } from "react";
import { usePeticion, OperacionesContext } from "../herramientas";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"

import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";


export default function Index() {

  // const [operaciones, setOperaciones] = useState();
  const peticion = usePeticion();
  const navigate = useNavigate();
  const operaciones = useContext(OperacionesContext)

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Sistema BM Importaciones
      </Typography>
      <List>
      {operaciones}
      </List>
      <Fab href="https://chat.whatsapp.com/BRyLlG16SoV1z11OX81bk9" target="_blank" sx={{ position: "fixed", bottom: 16, right: 16 }}  color="secondary">
        <Icon>question_answer</Icon>
      </Fab>
    </>
  );
}
