import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFecha, usePeticion } from "../herramientas";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [detalles, setDetalles] = useState({});
  const nombreOperacion = "Consultar venta";
  const afecha = useFecha();

  const params = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("venta_consultar/detalles/" + params.id_venta, {}, nombreOperacion, "No se han podido cargar los detalles de esta venta", (json) => {
      setDetalles(json.detalles);
      setEstado("cargado");
    });
  }, []);

  if (estado == "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
          <br />
          <Chip label={detalles.estado} color={detalles.estado == "Debe" ? "error" : detalles.estado == "Entregado" ? "success" : "primary"} />
        </Typography>
        <Typography variant="h5">Datos</Typography>
        <List>
          <ListItem
            secondaryAction={
              <IconButton edge="end" onClick={() => navigate("/cliente_consultar/detalles/" + detalles.id_cliente)} aria-label="delete">
                <Icon>info</Icon>
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Icon>person</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={detalles.nombre_cliente} secondary="Cliente" />
          </ListItem>
          <ListItem
            secondaryAction={
              <IconButton edge="end" onClick={() => navigate("/producto_consultar/detalles/" + detalles.id_producto)} aria-label="delete">
                <Icon>info</Icon>
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Icon>inventory_2</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={detalles.nombre_producto} secondary={detalles.codigo_producto} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Icon>point_of_sale</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={detalles.nombre_vendedor} secondary="Vendedor" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Icon>event</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={afecha(detalles.fecha_creacion)} secondary="Fecha de creación" />
          </ListItem>
          {detalles.fecha_entrega && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Icon>event</Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={afecha(detalles.fecha_entrega)} secondary="Fecha de entrega" />
            </ListItem>
          )}
        </List>
        <Typography variant="h5" gutterBottom>
          Detalles
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Cantidad
                  </TableCell>
                  <TableCell align="right">{detalles.cantidad}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Precio de venta
                  </TableCell>
                  <TableCell align="right">S/ {detalles.precio_venta}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Importe
                  </TableCell>
                  <TableCell align="right">S/ {detalles.importe}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Abono
                  </TableCell>
                  <TableCell align="right">S/ {detalles.abono}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box pb={3}>
          <Typography variant="h5" gutterBottom>
            Destino
          </Typography>
          <Typography variant="body1" gutterBottom>
            {detalles.destino}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Observaciones
          </Typography>
          <Typography variant="body1" gutterBottom>
            {detalles.observaciones}
          </Typography>
        </Box>
      </>
    );
  }
}
