import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../conexion";
import { usePeticion } from "../herramientas";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [detalles, setDetalles] = useState({});
  const nombreOperacion = "Consultar cliente";
  const params = useParams();
  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(function () {
    peticion("cliente_consultar/detalles/" + params.id_cliente, {}, nombreOperacion, "Los detalles del cliente no han podido ser cargados", (json) => {
      setDetalles(json.detalles);
      setEstado("cargado");
    });
  }, []);

  if (estado == "cargando") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          {nombreOperacion}
        </Typography>
        <Typography variant="h5" gutterBottom>
          Datos
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nombre
                  </TableCell>
                  <TableCell align="right">{detalles.nombre}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    DNI
                  </TableCell>
                  <TableCell align="right">{detalles.dni}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Teléfono
                  </TableCell>
                  <TableCell align="right">{detalles.telefono}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Ciudad
                  </TableCell>
                  <TableCell align="right">{detalles.ciudad}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="h5" gutterBottom>
          Ventas
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ color: "warning.main" }} component="th" scope="row">
                    Ventas en deuda
                  </TableCell>
                  <TableCell sx={{ color: "warning.main" }} align="right">
                    {detalles.ventas_debe}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: "primary.main" }} component="th" scope="row">
                    Ventas pagadas
                  </TableCell>
                  <TableCell sx={{ color: "primary.main" }} align="right">
                    {detalles.ventas_pagado}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: "success.main" }} component="th" scope="row">
                    Ventas entregadas
                  </TableCell>
                  <TableCell sx={{ color: "success.main" }} align="right">
                    {detalles.ventas_entregado}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box pb={2}>
          <Typography variant="h5" gutterBottom>
            Destino
          </Typography>
          <Typography variant="body1" gutterBottom>
            {detalles.destino}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Observaciones
          </Typography>
          <Typography variant="body1" gutterBottom>
            {detalles.observaciones}
          </Typography>
        </Box>
      </>
    );
  }
}
